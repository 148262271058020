import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { DefaultComponent } from '../modules/dashboard/default/default.component';
import { FlytAuthGuard } from '../guards/flyt-auth-guard.guard';
import { TeamMemberMenuModule } from '../menu/team-member-menu/team-member-menu.module'
import { AdminMenuModule } from '../menu/admin-menu/admin-menu.module'
import { Route } from '@angular/router';

/*export interface Route {
  path?:string;
  data?:any;
  outlet?:string;
  animation?:any;
  component?:any;
  children?: any[];
  canActivate?: any;
  canDeactivate?: any;
}*/
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
  	route?:Route;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;
	public menu: {[key: string]: Menu[]} = {};
	public items: BehaviorSubject<Menu[]>;
	
	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
		this.menu['ADMIN'] = AdminMenuModule.MENUITEMS;
		this.menu['TEAM_MEMBER'] = TeamMemberMenuModule.MENUITEMS;
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	getMenu(role:string): Menu[] {
		const menu = this.menu[role];
		this.items = new BehaviorSubject<Menu[]>(menu);
		return this.menu[role];

	}

}
