import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DataTablePagerComponent as SuperDataTablePagerComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-datatable-pager',
  template: `
    <ul class="pager">
      <li [class.disabled]="!canPrevious()">
        <a
          href="javascript:void(0)"
          (click)="selectPage(1)">
          <i class="fa fa-angle-double-left"></i>
        </a>
      </li>
      <li [class.disabled]="!canPrevious()">
        <a
          href="javascript:void(0)"
          (click)="prevPage()">
          <i class="fa fa-angle-left"></i>
        </a>
      </li>
      <li
        class="pages"
        *ngFor="let pg of pages"
        [class.active]="pg.number === page">
        <a
          href="javascript:void(0)"
          (click)="selectPage(pg.number)">
          {{pg.text}}
        </a>
      </li>
      <li [class.disabled]="!canNext()">
        <a
          href="javascript:void(0)"
          (click)="nextPage()">
          <i class="fa fa-angle-right"></i>
        </a>
      </li>
      <li [class.disabled]="!canNext()">
        <a
          href="javascript:void(0)"
          (click)="selectPage(totalPages)">
          <i class="fa fa-angle-double-right"></i>
        </a>
      </li>
      <label for="per-page" style="color: #777777; padding-left:30px;">
        Total {{ this.count }} entries
      </label>
    </ul>
    <div>
      <div container="body" ngbDropdown class="d-inline-block" placement="bottom">
        <!--button class="m-r-10 btn btn-outline-danger" (click)="clearFilters()"><i class="p-r-5 icofont icofont-ui-remove"></i><span><strong>CLEAR FILTERS</strong></span></button-->
        <!--button class="m-r-10 btn btn-outline-primary" (click)="showFilters()"><i class="p-r-5 icofont icofont-settings"></i><span><strong>SHOW FILTERS</strong></span></button-->
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle ><i style="font-weight: 800;" class="p-r-5 icon-export"></i><span><strong>EXPORT AS</strong></span></button>
        <div  ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button (click)="downloadPDF()" class="dropdown-item"><i class="p-r-5 fa fa-file-pdf-o"></i>PDF</button>
          <button (click)="downloadExcel()" class="dropdown-item"><i class="p-r-5 fa fa-file-excel-o"></i>MS Excel</button>
          <button (click)="downloadCSV()" class="dropdown-item"><i class="p-r-5 fa fa-file-code-o"></i>CSV</button>
        </div>
      </div>
    </div>
  `,
  host: {
    class: 'datatable-pager'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTablePagerComponent extends SuperDataTablePagerComponent {
  @Input() pagerLeftArrowIcon: string = 'icon-left';
  @Input() pagerRightArrowIcon: string = 'icon-right';
  @Input() pagerPreviousIcon: string = 'icon-prev';
  @Input() pagerNextIcon: string = 'icon-skip';

  @Input()
  set size(val: number) {
    this._size = val;
    this.pages = this.calcPages();
  }

  get size(): number {
    return this._size;
  }

  @Input()
  set count(val: number) {
    this._count = val;
    this.pages = this.calcPages();
  }

  get count(): number {
    return this._count;
  }

  @Input()
  set page(val: number) {
    this._page = val;
    this.pages = this.calcPages();
  }

  get page(): number {
    return this._page;
  }

  get totalPages(): number {
    const count = this.size < 1 ? 1 : Math.ceil(this.count / this.size);
    return Math.max(count || 0, 1);
  }

  @Output() change: EventEmitter<any> = new EventEmitter();

  public _visiblePagesCount: number = 3;

  @Input()
  set visiblePagesCount(val: number) {
    this._visiblePagesCount = val;
    this.pages = this.calcPages();
  }

  get visiblePagesCount(): number {
    return this._visiblePagesCount;
  }

  _count: number = 0;
  _page: number = 1;
  _size: number = 0;
  pages: any;

  canPrevious(): boolean {
    return this.page > 1;
  }

  canNext(): boolean {
    return this.page < this.totalPages;
  }

  prevPage(): void {
    this.selectPage(this.page - 1);
  }

  nextPage(): void {
    this.selectPage(this.page + 1);
  }

  selectPage(page: number): void {
    if (page > 0 && page <= this.totalPages && page !== this.page) {
      this.page = page;

      this.change.emit({
        page
      });
    }
  }

  calcPages(page?: number): any[] {
    const pages:any = [];
    let startPage = 1;
    let endPage = this.totalPages;
    const maxSize = this.visiblePagesCount;
    const isMaxSized = maxSize < this.totalPages;

    page = page || this.page;

    if (isMaxSized) {
      startPage = page - Math.floor(maxSize / 2);
      endPage = page + Math.floor(maxSize / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + maxSize - 1, this.totalPages);
      } else if (endPage > this.totalPages) {
        startPage = Math.max(this.totalPages - maxSize + 1, 1);
        endPage = this.totalPages;
      }
    }

    for (let num = startPage; num <= endPage; num++) {
      pages.push({
        number: num,
        text: <string><any>num
      });
    }

    return pages;
  }

  @Output() download = new EventEmitter<string>();

  downloadPDF(){
    this.download.emit('PDF');
  }

  downloadExcel(){
    this.download.emit('XLS');
  }

  downloadCSV(){
    this.download.emit('CSV');
  }

  @Output() showFilter = new EventEmitter<void>();
  showFilters(){
    this.showFilter.emit();
  }

  @Output() clearFilter = new EventEmitter<void>();
  clearFilters(){
    this.clearFilter.emit();
  }
}
