import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ListBaseComponent } from '../../../components/list-base/list-base.component';
import { Enquiry } from '../../../types/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../../app/app.service';
import { debounceTime, Subject } from 'rxjs';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-list-enquiry',
  templateUrl: './list-enquiry.component.html',
  styleUrls: ['./list-enquiry.component.scss']
})
export class ListEnquiryComponent extends ListBaseComponent<Enquiry> implements OnInit {

  private _message = new Subject<string>();

  private interval;
  
  alertError : boolean = false;

  message : string = '';
  
  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute) {
      super(http,breadcrumbService,appService,offcanvasService,router,route); 
     }

  ngOnInit(): void {
    this.page = {
      limit: 10,
      count: 0,
      offset: 0,
      orderBy: 'id',
      orderDir: 'desc'
    };
    this.pageCallback({ offset: 0 });

    this._message.subscribe((message) => (this.message = message));
    this._message.pipe(debounceTime(5000)).subscribe(() => {
			this.alertError = false;
		});

    this.interval = setInterval(() => {
      this.reloadPage();
    }, 60000);

  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
 

  reloadPage(){
    this.reloadTable()
  }

  clearFilter(){
    const dateRangeInput = document.getElementById("dateRangeInput");
    /*this.fromDate = undefined;
    this.toDate = undefined;
    this.selectedStatus = "0";
    this.notificationNumber = "";
    if( dateRangeInput )
      this.renderer.setProperty(dateRangeInput, 'value', "");*/
    super.clearFilter();
  }

  onActivate(event){
    if(event.type == 'click') {
      console.log(event.row);
      this.router.navigate(['/enquiries/', event.row.uuid]);
    }
  }

  addNewContractor(){
    
  }

  protected get loadingUrl(){
    return `${environment.serverUrl}/enquiry`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/enquiry/export`;
  }

  protected get exportFileName(){
    return `Enquiry Report`;
  }

}
