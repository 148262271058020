import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { IS_UPLOAD_REQUEST, Product } from 'src/app/types/types';
import { environment } from '../../../../environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { fileURLToPath } from 'url';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  loading = false;

  public ClassicEditor = ClassicEditor;
  
  product: Product = undefined;

  @Output() productEntry: EventEmitter<Product> = new EventEmitter();

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';
  imageSrc = undefined;

  selectedImage: File = undefined;
  selectedTechSheet: File = undefined;

  techSheetAvaialble = false;
  techSheetUrl = undefined

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,private http: HttpClient,private toastrService: ToastrService) { }

  ngOnInit(): void {

    if( this.product ){
      this.form = this.fb.group({
        id:this.product.id,
        name: [this.product.name,[Validators.required]],
        attr1:[this.product?.attr1],
        attr2:[this.product?.attr2],
        attr3:[this.product?.attr3],
        attr4:[this.product?.attr4],
        attr5:[this.product?.attr5],
        description:[this.product.description,[Validators.required]],
      });
      if( this.product.image ){
        this.imageSrc = this.product.image
      }
      if( this.product.techSheet ){
        this.techSheetAvaialble = true
        this.techSheetUrl = this.product.techSheet
      }
      
      this.parseJSONFilter(this.product.filter)
    }
    else{
      this.form = this.fb.group({
        name: ['',[Validators.required]],
        attr1:[''],
        attr2:[''],
        attr3:[''],
        attr4:[''],
        attr5:[''],
        description:['',[Validators.required]],
      });
    }
  }

  parseJSONFilter(filter) {
    //const filter = JSON.parse(json);
    const tags = filter.tag;
    this.atInternal = (tags.indexOf("tg.at-interior") != -1 ) ? true : false;
    this.atExternal = (tags.indexOf("tg.at-exterior") != -1 ) ? true : false;
    this.ttCeramic = (tags.indexOf("tg.tt-ceramic") != -1 ) ? true : false;
    this.ttVitrified = (tags.indexOf("tg.tt-vitrified") != -1 ) ? true : false;
    this.ttNaturalStone = (tags.indexOf("tg.tt-natural-stone") != -1 ) ? true : false;
    this.tsSmall = (tags.indexOf("tg.ts-small") != -1 ) ? true : false;
    this.tsLarge = (tags.indexOf("tg.ts-large") != -1 ) ? true : false;
    this.aaWall = (tags.indexOf("tg.aa-wall") != -1 ) ? true : false;
    this.aaFloor = (tags.indexOf("tg.aa-floor") != -1 ) ? true : false;
    this.tsTileOnTile = (tags.indexOf("tg.tsa-tile-on-tile") != -1 ) ? true : false;
    this.tsCementBoard = (tags.indexOf("tg.tsa-cement-board") != -1 ) ? true : false;
    this.tsPlaster = (tags.indexOf("tg.tsa-plaster") != -1 ) ? true : false;

    this.jointFillerEpoxy = (tags.indexOf("tje.type-jfe") != -1 ) ? true : false;
    this.glassBondEpoxy = (tags.indexOf("tje.type-gbe") != -1 ) ? true : false;
    this.twoPartEpoxy = (tags.indexOf("tje.type-ce-2pe") != -1 ) ? true : false;
    this.threePartEpoxy = (tags.indexOf("tje.type-ce-3pe") != -1 ) ? true : false;
    this.twoKWaterProof = (tags.indexOf("wp.type-2wp") != -1 ) ? true : false;
    this.bathroomWaterProof = (tags.indexOf("wp.type-bwp") != -1 ) ? true : false;
    this.acrylicWaterProof = (tags.indexOf("wp.type-awp") != -1 ) ? true : false;
  }

  generateJSONFilter() : string{
    const json = {}
    json["tag"] = [];

    (this.atInternal) && json["tag"].push("tg.at-interior");
    (this.atExternal) && json["tag"].push("tg.at-exterior");
    (this.ttCeramic) && json["tag"].push("tg.tt-ceramic");
    (this.ttVitrified) && json["tag"].push("tg.tt-vitrified");
    (this.ttNaturalStone) && json["tag"].push("tg.tt-natural-stone");
    (this.tsSmall) && json["tag"].push("tg.ts-small");
    (this.tsLarge) && json["tag"].push("tg.ts-large");
    (this.aaWall) && json["tag"].push("tg.aa-wall");
    (this.aaFloor) && json["tag"].push("tg.aa-floor");
    (this.tsTileOnTile) && json["tag"].push("tg.tsa-tile-on-tile");
    (this.tsCementBoard) && json["tag"].push("tg.tsa-cement-board");
    (this.tsPlaster) && json["tag"].push("tg.tsa-plaster");

    (this.jointFillerEpoxy) && json["tag"].push("tje.type-jfe");
    (this.glassBondEpoxy) && json["tag"].push("tje.type-gbe");
    (this.twoPartEpoxy) && json["tag"].push("tje.type-ce-2pe");
    (this.threePartEpoxy) && json["tag"].push("tje.type-ce-3pe");
    (this.twoKWaterProof) && json["tag"].push("wp.type-2wp");
    (this.bathroomWaterProof) && json["tag"].push("wp.type-bwp");
    (this.acrylicWaterProof) && json["tag"].push("wp.type-awp");

    return JSON.stringify(json || null);
  }
  
  async addImage(event): Promise<void> {
    if (event.target.files.length === 0)
      return;
    this.selectedImage = event.target.files[0];
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    this.imageSrc = await this.toBase64(event.target.files[0]);
  }

  addTechSheet(event): void {
    this.selectedTechSheet = event.target.files[0];
    this.techSheetAvaialble = true;
  }

  removeImage(){
    this.imageSrc = undefined;
    this.selectedImage = undefined
  }

  removeTechSheet(){
    this.selectedTechSheet = undefined
    this.techSheetAvaialble = false;
  }
  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/product/`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/product/${this.form.value.id}`
    }
    
    const formData: FormData = new FormData();    

    for (const field in this.form.controls) { // 'field' is a string
      const control = this.form.get(field); // 'control' is a FormControl  
      formData.append(field,control.value)
    
    }
    formData.append("filter",this.generateJSONFilter())

    if( this.selectedImage)
      formData.append("image",this.selectedImage);

    if(this.selectedTechSheet) 
      formData.append("techSheet",this.selectedTechSheet)

    this.loading = true
    this.http.post(postUrl, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
      }).subscribe({
          next: (v:Product) => {
            console.log(v)
            this.activeModal.close('Success')
            this.toastrService.success('Product added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
            this.productEntry.emit(v);
            this.loading = false
          },
          error: (e) => {
            console.error(e);
            this.showMessage("error",`Adding new Product failed. Message:${e.message}`);
            this.loading = false
          },
          complete: () => console.info('complete')
        });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  atInternal: boolean = false;
  atExternal: boolean = false;
  ttCeramic: boolean = false;
  ttVitrified: boolean = false;
  ttNaturalStone: boolean = false;
  tsSmall: boolean = false;
  tsLarge: boolean = false;
  aaWall: boolean = false;
  aaFloor: boolean = false;
  tsTileOnTile: boolean = false;
  tsCementBoard: boolean = false;
  tsPlaster: boolean = false;

  jointFillerEpoxy: boolean = false;
  glassBondEpoxy: boolean = false;
  twoPartEpoxy: boolean = false;
  threePartEpoxy: boolean = false;
  twoKWaterProof: boolean = false;
  bathroomWaterProof: boolean = false;
  acrylicWaterProof: boolean = false;

}


