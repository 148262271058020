<form (keydown.enter)="$event.preventDefault()" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="container-fluid">
        <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'" (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
        <div class="row">
            <div class="col-sm-6 col-xl-9">
                <div class="card">
                    <div class="row">
                        <div class="col">
                            <div class="card shadow shadow-showcase">
                                <div class="card-body row equal" style="padding:0;margin:20px;display: flex;align-items: center;">
                                    <div class="col-sm-6 col-xl-4 form-group m-form__group vw-data-col-border">
                                        <label><strong>Mobile Number</strong></label>
                                        <div><strong>{{enquiry?.mobileNumber ? enquiry?.mobileNumber : "--"}}</strong></div>
                                    </div>
                                    <div class="col-sm-6 col-xl-4 form-group m-form__group vw-data-col-border">
                                        <label><strong>Enquiry Date</strong></label>
                                        <div>{{enquiry?.enquiryDate ? enquiry?.enquiryDate.split("T")[0] : "--"}}</div>
                                    </div>
                                    <div class="col-sm-6 col-xl-4 form-group m-form__group vw-data-col-border">
                                        <label><strong>Current Status</strong></label>
                                        <div><strong>{{enquiry?.enquiryStatus ? enquiry?.enquiryStatus : "--"}}</strong></div>
                                    </div>
                                    <div class="col-sm-6 col-xl-4 form-group m-form__group vw-data-col-border">
                                        <label><strong>Next Followup date</strong></label>
                                        <div>{{enquiry?.followupDate ? enquiry?.followupDate.split("T")[0] : "--"}}</div>
                                    </div>
                                    <div class="col-sm-6 col-xl-4 form-group m-form__group vw-data-col-border">
                                        <label><strong>Contractor Name</strong></label>
                                        <div>{{enquiry?.contractor ? enquiry?.contractor.name : "--"}}</div>
                                    </div>
                                    <div class="col-sm-6 col-xl-4 form-group m-form__group vw-data-col-border">
                                        <label>&nbsp;</label>
                                        <div>&nbsp;</div>
                                    </div>
                                    <div class="col-sm-12 col-xl-12 form-group m-form__group vw-data-col-border">
                                        <label>Current Remarks</label>
                                        <div>{{enquiry?.remarks ? enquiry?.remarks : "--"}}</div>
                                    </div>
                                </div>
                                <div class="card-body row equal" style="padding:0;margin:20px;display: flex;align-items: center;">
                                    <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                        <label>Remarks</label>
=                                        <ckeditor formControlName="remarks" [editor]="ClassicEditor"></ckeditor>
                                    </div>
                                    <div class="col-sm-12 col-xl-6 form-group m-form__group">
                                        <label >Followup Date </label>
                                        <span class="text-danger">*</span>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                            </div>
                                            <input  type="date" autofocus formControlName="followupDate" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-xl-6 form-group m-form__group">

                                    </div>
                                    <div class="col-sm-12 col-xl-6 form-group m-form__group">
                                        <label>Enquiry Status</label>
                                        <span class="text-danger">*</span>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                            </div>
                                            <select  formControlName="enquiryStatus" class="form-control digits">  
                                                <option value="0">--Select--</option>  
                                                <option value="Open">Open</option>  
                                                <option value="Closed">Closed</option>  
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer" style="text-align:right;">
                                    <button type="submit" class="btn btn-primary me-1">Save Enquiry</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card">

                    <div class="chat-box">
                        <div class="chat-right-aside">
                            <div class="chat">

                                <div style="height:calc(100vh - 160px); margin-bottom:10px" class="chat-history chat-msg-box custom-scrollbar">
                                    <ul>
                                    <li *ngFor="let chat of chats" [ngClass]="{'clearfix': chat.type == 's' }">
                                        <div style="border: 3px solid #f6f7fb;" class="message my-message mb-0" *ngIf="chat.type == 'r'; else myChat">
                                            <ng-container *ngIf="!chat.messageType || chat.messageType == 'chat'"><strong>{{chat?.value}}</strong></ng-container>
                                            <ng-container *ngIf="chat.messageType == 'image'"><img style="max-width: 200px;" src="{{environment.serverAssetUrl}}public/temp/{{enquiry?.mobileNumber}}/{{chat.value}}"></ng-container>
                                            <ng-container *ngIf="chat.messageType == 'video'">
                                                <div class="video">
                                                    <video style="max-width: 200px;" controls (click)="toggleVideo()">
                                                        <source src="{{environment.serverAssetUrl}}public/temp/{{enquiry?.mobileNumber}}/{{chat.value}}" type="video/mp4" />
                                                        Browser not supported
                                                    </video>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="chat.messageType == 'audio' || chat.messageType == 'ptt'">
                                                <audio style="max-width: 200px;" controls>
                                                    <source type="audio/mpeg" src='{{environment.serverAssetUrl}}public/temp/{{enquiry?.mobileNumber}}/{{chat.value}}'>
                                                </audio>
                                            </ng-container>
                                            <ng-container *ngIf="chat.messageType == 'document'">
                                                <a href="{{environment.serverAssetUrl}}public/temp/{{enquiry?.mobileNumber}}/{{chat.value}}" target="_blank"><img style="max-width:100px" src="/assets/images/file.png" alt="Document" /></a>
                                            </ng-container>
                                            
                                        </div>
                                        <ng-template #myChat>
                                        <div [innerHTML]=chat?.value style="background-color: rgb(220, 240, 228);" class="message other-message pull-right">
                                        </div>
                                        </ng-template>
                                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>