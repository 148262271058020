import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../../services/nav-service.service';
import { DataSharingService } from '../../../services/data-sharing-service.service';
import { Subscription } from 'rxjs';
import { User } from '../../../types/types'
import { AuthService } from '../../../services/auth-service.service';
import { ApiSharingService } from '../../../services/api-sharing-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SidebarComponent {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  user : User;
  userSub: Subscription;
  userProfileImageSource = undefined;

  constructor(private router: Router, public navServices: NavService, 
      private authService : AuthService, private domSanitizer : DomSanitizer, 
      private apiSharingService: ApiSharingService ) {

    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    })
    this.setUserProfile();
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  setUserProfile(){
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      this.userProfileImageSource = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.user?.profilePicture}`);
    });
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  async readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    this.userProfileImageSource = await this.toBase64(event.target.files[0]);
    this.apiSharingService.updateUserProfilePic(event.target.files[0]);
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

}
