import { HttpClient, HttpContext } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../../../app.service';
import { Chat, Enquiry, IS_UPLOAD_REQUEST } from '../../../types/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-enquiry-details',
  templateUrl: './enquiry-details.component.html',
  styleUrls: ['./enquiry-details.component.scss']
})
export class EnquiryDetailsComponent implements OnInit {

  environment = environment
  enquiry: Enquiry = undefined

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  public ClassicEditor = ClassicEditor;
  
  message : string = '';

  chats: Chat[]

  constructor(private route: ActivatedRoute,private fb: FormBuilder,protected http: HttpClient,
    private toastrService: ToastrService, protected router: Router,private breadcrumbService: BreadcrumbService, 
    private appService: AppService) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      remarks: [''],
      followupDate: [''],
      enquiryStatus: ['0'],
    });

    const id = this.route.snapshot.params['id'];
    this.loadEnquiry(id);
  }

  loadEnquiry(id){
    this.http.get<Enquiry>(`${environment.serverUrl}/enquiry/${id}`)
    .subscribe({
      next: (occurrence) => {
        this.enquiry = occurrence;
        this.breadcrumbService.set('enquiries/:id', id);
        //this.appService.setTitle(this.occurrence?.occurrenceFileNumber);
        this.loadFormData();
        this.chats = JSON.parse(this.enquiry.log || "[]" )
        this.chats.map((chat) => {
          chat.value = chat.value.replace(new RegExp("\n", 'g'), "<br><br>")
          return chat
        })

      },
      error: (e) => {
        console.error(e);
        this.toastrService.error(`Error`, 'Enquiry Not Found',{ closeButton:true,disableTimeOut:true,positionClass: 'toast-top-full-width',enableHtml:true });
        this.router.navigate(['/enquiries']);
      }
    });
  }

  loadFormData(){
    this.form = this.fb.group({
      remarks: [this.enquiry?.remarks],
      followupDate: this.enquiry?.followupDate?.split("T")[0],
      enquiryStatus: this.enquiry?.enquiryStatus,
    });
  }

  onSubmit(){
    const formData: FormData = new FormData();    

    for (const field in this.form.controls) { // 'field' is a string
      const control = this.form.get(field); // 'control' is a FormControl  
      formData.append(field,control.value)
    }

    this.http.post(`${environment.serverUrl}/enquiry/${this.enquiry.id}`, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
    }).subscribe({
          next: (v:Enquiry) => {
            console.log(v)
            this.toastrService.success('Enquiry Updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
            this.router.navigate(['/enquiries']);
          },
          error: (e) => {
            console.error(e);
            this.showMessage("error",`Updating Enquiry Failed. Message:${e.message}`);
          },
          complete: () => console.info('complete')
        });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }
}
