import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth-service.service';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public errorMessage: any;
  public error: {};

  constructor(public authService: AuthService,
    private fb: FormBuilder,
    private router: Router) {
    this.loginForm = fb.group({
      email: ['admin', [Validators.required]],
      password: ['admin', Validators.required]
    });
  }

  ngOnInit() {}

  login() {
    this.errorMessage = '';
    this.authService.login(this.loginForm.value['email'], this.loginForm.value['password'])
    .subscribe({
      next: () => {
        window.location.href = `${window.location.protocol}//${window.location.host}/dashboard`;
      },
      error: (e) => {
        console.error(e);
        this.errorMessage = e['errorDesc'];
      }
    })
  }
}
