<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Closed')"></button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="card-body">
        <div *ngIf="contractor == undefined else edit_contractor" ><div class="ribbon ribbon-primary">Add New Contractor</div></div>
        <ng-template #edit_contractor><div class="ribbon ribbon-primary">Edit Contractor Details</div></ng-template>
        <div class="row">
            <div class="col">
                <div class="card shadow shadow-showcase">
                    <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'" (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
                    <div class="card-body row" style="margin-top:30px;">
                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <label >Contractor Name</label>
                            <span class="text-danger">*</span>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="name" type="text" class="form-control" placeholder="Name of the Contractor" required>
                            </div>
                            <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                                <div *ngIf="form.controls.name.errors.required">
                                    Contractor Name  is required
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <label >Mobile Number </label>
                            <span class="text-danger">*</span>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">+91</span>
                                </div>
                                <input autofocus formControlName="mobileNumber" type="text" class="form-control" placeholder="Format - XXXXXXXXXX" required maxlength=10 (keypress)="numberOnly($event)">
                            </div>
                            <div *ngIf="form.controls.mobileNumber.invalid && (form.controls.mobileNumber.dirty || form.controls.mobileNumber.touched)" class="text text-danger">
                                <div *ngIf="form.controls.mobileNumber.errors.required">
                                    Mobile Number is required
                                </div>
                                <div *ngIf="form.controls.mobileNumber.errors.pattern || form.controls.mobileNumber.errors.maxlength || form.controls.mobileNumber.errors.minlength " class="text text-danger">
                                    Mobile number must be 10 numbers
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <label >Internal Code </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="internalCode" type="text" class="form-control" placeholder="Internal code from Tally">
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" style="text-align:right;">
                        <button type="submit" class="btn btn-primary me-1">Save Contractor Details</button>
                        <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Closed')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>