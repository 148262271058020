import { Component } from '@angular/core';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { map, Observable, of, Subject, Subscription } from 'rxjs';
import { AuthService } from './services/auth-service.service';
import { slideInAnimation } from './animations/animations'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent {
  title = 'Flyt';

  constructor(private contexts: ChildrenOutletContexts,private readonly router: Router,) {
    //router.events
    //  .subscribe(console.log)
  }

  
  getRouteAnimationData(){
    
  }
}

