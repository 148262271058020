import { HttpContextToken } from "@angular/common/http";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export const IS_UPLOAD_REQUEST = new HttpContextToken<boolean>(() => false);

export interface LoginResponse {
    accessToken: string;
    refreshToken?: string;
    user: User;
}

export class User {  
    id?: number;
    username?: string;
    email?: string;
    name?: string;
    profilePicture?: string;
    bannerPicture?: string;
    lastLoginDate?: string;
    staffId?: string;
    role?: string;
}

export class LookupData {  
    id: number;
    value?: string;
    acronym?: string;
    description?: string;
    name?: string;
}

export class Attachment{
    id: string;
    fileName?: string;
    type?: string;
    fileSize?: string;
    storagePath?: string;
}

export interface Contractor {
    id:number;
    name:string;
    mobileNumber:string;
    internalCode:string;
    createdAt:string;
    updatedAt:string;
}

export interface Product {
    id:number;
    name:string;
    internalCode:string;
    attr1:string;
    attr2:string;
    attr3:string;
    attr4:string;
    attr5:string;
    filter:string;
    image:string;
    techSheet:string;
    description:string;
    createdAt:string;
    updatedAt:string;
}

export interface Chat {
    type: string;
    messageType: string;
    value: string;
}


export interface Enquiry {
    id:number;
    uuid:string;
    name:string;
    mobileNumber:string;
    enquiryDate:string;
    contractor?:Contractor;
    enquiryStatus:string;
    followupDate:string;
    remarks:string;
    log:string;
    createdAt:string;
    updatedAt:string;
}

export interface Alert {
    type: string;
    message: string;
}

export interface Comment {
    id: number;
    postedBy: User;
    lastModified: string;
    text: string;
    replies?: Array<Comment>;
}
