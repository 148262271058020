import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListEnquiryComponent } from './list-enquiry/list-enquiry.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { EnquiryDetailsComponent } from './enquiry-details/enquiry-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [
    ListEnquiryComponent,
    EnquiryDetailsComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    CKEditorModule
  ]
})
export class EnquiryModule { }
