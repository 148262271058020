import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { PrivateRoutesModule } from './routes/private-routes/private-routes.module'

const routes: Routes = [
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'disabled',
    enableTracing: true
}),PrivateRoutesModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
