import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivateRoutesRoutingModule } from './private-routes-routing.module';
import { Route, Router, Routes } from '@angular/router';
import { NavService, Menu } from '../../services/nav-service.service';
import { LoginComponent } from '../../auth/login/login.component';
import { ContentLayoutComponent } from '../../shared/components/layout/content-layout/content-layout.component';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import { AuthService } from '../../services/auth-service.service';
import { Error404Component } from '../../pages/error-pages/error404/error404.component';
import { User } from '../../types/types';
import { map, pipe, Subscription, tap } from 'rxjs';
import { DashboardModule } from 'src/app/modules/dashboard/dashboard.module';
import { DefaultComponent } from 'src/app/modules/dashboard/default/default.component';
import { HttpErrorResponse } from '@angular/common/http';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PrivateRoutesRoutingModule,
    RouterModule
  ],
  exports:[
    RouterModule
  ]
})
export class PrivateRoutesModule {

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService, private authService: AuthService) {
    const routes: Routes = [
      {
        path: 'auth/login',
        component: LoginComponent
      },
      {
        path: '',
        component: ContentLayoutComponent,
        pathMatch:'prefix',
        canActivate: [FlytAuthGuard],
        children: [
        ],
        data: {
          role: 'ROLE_USER,ROLE_ADMIN,ROLE_DIRECTOR,ROLE_NOTIFIER',
          breadcrumb: {
            label: 'Home',
          }
        }
      },
      {
        path: 'error',
        loadChildren: () => import('../../pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
      },
      {
        path: '**',
        canActivate: [FlytAuthGuard],
        component: Error404Component,
      }
    ];

    this.authService.getCurrentUser().subscribe({
        next:((user:User) => {
          this.menuItems = this.navServices.getMenu(user?.role);
          if (this.menuItems) {
            this.menuItems.forEach(item => {
              if (item.route) {
                //routes.splice(1,0,item.route);
                routes[1].children.push(item.route);
              }
            })
            this.router.resetConfig(routes);
            this.router.initialNavigation()
            this.printpath('', this.router.config);
          }        
        }),
        error: (err) => {
          //Handled in Auth service
          this.authService.logout()
        },
      });
      
      this.router.resetConfig(routes);
  }

  printpath(parent: String, config: Route[]) {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];
      console.log(parent + '/' + route.path);
      if (route.children) {
        const currentPath = route.path ? parent + '/' + route.path : parent;
        this.printpath(currentPath, route.children);
      }
    }
  }
}
