import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListContractorComponent } from './list-contractor/list-contractor.component';
import { AddContractorComponent } from './add-contractor/add-contractor.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    ListContractorComponent,
    AddContractorComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule
  ]
})
export class ContractorModule { }
