import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ListBaseComponent } from '../../../components/list-base/list-base.component';
import { Product } from '../../../types/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../../app/app.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddProductComponent } from '../add-product/add-product.component';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss']
})
export class ListProductComponent extends ListBaseComponent<Product> implements OnInit {

  productRegistrationObserver: Subscription;
  
  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';
  
  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal) {
      super(http,breadcrumbService,appService,offcanvasService,router,route); 
     }

  ngOnInit(): void {
    
    this.pageCallback({ offset: 0 });

    this._message.subscribe((message) => (this.message = message));
    this._message.pipe(debounceTime(5000)).subscribe(() => {
			this.alertError = false;
		});
  }

  clearFilter(){
    const dateRangeInput = document.getElementById("dateRangeInput");
    /*this.fromDate = undefined;
    this.toDate = undefined;
    this.selectedStatus = "0";
    this.notificationNumber = "";
    if( dateRangeInput )
      this.renderer.setProperty(dateRangeInput, 'value', "");*/
    super.clearFilter();
  }

  onActivate(event){
    if(event.type == 'click') {
      console.log(event.row);
      this.editProduct(event.row)
    }
  }

  addNewProduct(){
    
    const modalRef = this.modalService.open(AddProductComponent,{ centered: true,size: 'xl' });
    modalRef.componentInstance.name = 'Add / Edit Product';
    
    if( this.productRegistrationObserver ){
      this.productRegistrationObserver.unsubscribe();
    }

    this.productRegistrationObserver = modalRef.componentInstance.productEntry.subscribe((contractor) => {
      this.reloadTable()
    });
    
  }

  editProduct(product){
    
    const modalRef = this.modalService.open(AddProductComponent,{ centered: true,size: 'xl' });
    modalRef.componentInstance.name = 'Add / Edit Product';
    
    if( this.productRegistrationObserver ){
      this.productRegistrationObserver.unsubscribe();
    }
  
    modalRef.componentInstance.product = product;

    this.productRegistrationObserver = modalRef.componentInstance.productEntry.subscribe((contractor) => {
      this.reloadTable()
    });
    
  }

  protected get loadingUrl(){
    return `${environment.serverUrl}/product`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/product/export`;
  }

  protected get exportFileName(){
    return `Products Report`;
  }

}
