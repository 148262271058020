import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Menu } from '../../services/nav-service.service';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import { DefaultComponent } from '../../modules/dashboard/default/default.component';
import { ConfirmationGuard } from '../../guards/confirmation-guard.guard';
import { ListContractorComponent } from '../../modules/contractor/list-contractor/list-contractor.component';
import { ListProductComponent } from '../../modules/product/list-product/list-product.component';
import { ListEnquiryComponent } from '../../modules/enquiry/list-enquiry/list-enquiry.component';
import { EnquiryDetailsComponent } from '../../modules/enquiry/enquiry-details/enquiry-details.component';
import {Router, RouterModule} from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    RouterModule
  ]
})
export class AdminMenuModule { 

  constructor(private router: Router){}
  
  public static MENUITEMS: Menu[] = [
		  {
        title: 'Dashboard', icon: 'home', type: 'link', active: false, path: '/dashboard',
        route:{
          path: 'dashboard',
          canActivate: [FlytAuthGuard],
          component: DefaultComponent,
          data: {
            breadcrumb: {
              label: 'Dashboard',
            },
            role: 'ROLE_ADMIN',
            animation: 'slideInAnimation'
          },
          children: []
        },
      },
      {
        title: 'All Enquiries', icon: 'file-plus', type: 'link', active: false, path: 'enquiries',
        route:{
          path: 'enquiries',
          canActivate: [FlytAuthGuard],
          children: [
            {
              path: '',
              pathMatch: 'full',
              canActivate: [FlytAuthGuard],
              component: ListEnquiryComponent,
              data: {
                title:"ALL ENQUIRIES",
                breadcrumb: {
                  label: "All Enquiries",
                  info: 'icofont icofont-files'
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            },
            {
              path: ':id',
              canActivate: [FlytAuthGuard],
              component: EnquiryDetailsComponent,
              data: {
                title:"Enquiry Details",
                breadcrumb: {
                  label: "Enquiry Details",
                  info: 'icofont icofont-files',
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Contractors', icon: 'file-plus', type: 'link', active: false, path: 'contractors',
        route:{
          path: 'contractors',
          canActivate: [FlytAuthGuard],
          children: [
            {
              path: '',
              pathMatch: 'full',
              canActivate: [FlytAuthGuard],
              component: ListContractorComponent,
              data: {
                title:"All Contractors",
                breadcrumb: {
                  label: "All Contractors",
                  info: 'icofont icofont-files'
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Products', icon: 'file-plus', type: 'link', active: false, path: 'products',
        route:{
          path: 'products',
          canActivate: [FlytAuthGuard],
          children: [
            {
              path: '',
              pathMatch: 'full',
              canActivate: [FlytAuthGuard],
              component: ListProductComponent,
              data: {
                title:"All Products",
                breadcrumb: {
                  label: "All Products",
                  info: 'icofont icofont-files'
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },

	]
}
