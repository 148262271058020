<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Closed')"></button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="card-body">
        <div *ngIf="product == undefined else edit_product" ><div class="ribbon ribbon-primary">Add New Product</div></div>
        <ng-template #edit_product><div class="ribbon ribbon-primary">Edit Product Details</div></ng-template>
        <div class="row">
            <div class="col-sm-12 col-xl-9 form-group m-form__group">
                <div class="card shadow shadow-showcase">
                    <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'" (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
                    <div class="card-body row" style="margin-top:30px;">
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Product Name</label>
                            <span class="text-danger">*</span>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="name" type="text" class="form-control" placeholder="Name of the Product" required>
                            </div>
                            <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                                <div *ngIf="form.controls.name.errors.required">
                                    Product Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 1 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr1" type="text" class="form-control" placeholder="Attribute 1">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 2 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr2" type="text" class="form-control" placeholder="Attribute 2">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 3 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr3" type="text" class="form-control" placeholder="Attribute 3">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 4 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr4" type="text" class="form-control" placeholder="Attribute 4">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 5 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr5" type="text" class="form-control" placeholder="Attribute 5">
                            </div>
                        </div>
                        <div class="card-header" style="padding-left:0;padding-bottom:10px;border-bottom:none;"><h5>Filters</h5><span>Set the filters for the product here</span></div>
                        
                        <div class="col-sm-12 col-xl-6 form-group m-form__group">
                            <label><strong>Area Type</strong> </label>
                            <div class="form-group m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.at-interior" id="tg.at-interior" [checked]="atInternal" (change)="atInternal = !atInternal">
                                    <label for="tg.at-interior">INTERNAL</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.at-exterior" id="tg.at-exterior" [checked]="atExternal" (change)="atExternal = !atExternal">
                                    <label for="tg.at-exterior">EXTERNAL</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xl-6 form-group m-form__group">
                            <label ><strong>Tile Type</strong></label>
                            <div class="form-group m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.tt-ceramic" id="tg.tt-ceramic" [checked]="ttCeramic" (change)="ttCeramic = !ttCeramic">
                                    <label for="tg.tt-ceramic">CERAMIC</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.tt-vitrified" id="tg.tt-vitrified" [checked]="ttVitrified" (change)="ttVitrified = !ttVitrified">
                                    <label for="tg.tt-vitrified">VITRIFIED</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.tt-natural-stone" id="tg.tt-natural-stone" [checked]="ttNaturalStone" (change)="ttNaturalStone = !ttNaturalStone">
                                    <label for="tg.tt-natural-stone"> NATURAL STONES</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-6 form-group m-form__group">
                            <label><strong>Tile Size</strong></label>
                            <div class="form-group m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.ts-small" id="tg.ts-small" [checked]="tsSmall" (change)="tsSmall = !tsSmall">
                                    <label for="tg.ts-small">SMALL</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.ts-large" id="tg.ts-large" [checked]="tsLarge" (change)="tsLarge = !tsLarge">
                                    <label for="tg.ts-large">LARGE</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xl-6 form-group m-form__group">
                            <label><strong>Application Area</strong></label>
                            <div class="form-group m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.aa-wall" id="tg.aa-wall" [checked]="aaWall" (change)="aaWall = !aaWall">
                                    <label for="tg.aa-wall">WALL</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.aa-floor" id="tg.aa-floor" [checked]="aaFloor" (change)="aaFloor = !aaFloor">
                                    <label for="tg.aa-floor">FLOOR</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xl-6 form-group m-form__group">
                            <label><strong>Substrate Type</strong></label>
                            <div class="form-group m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.ts-tile-on-tile" id="tg.ts-tile-on-tile" [checked]="tsTileOnTile" (change)="tsTileOnTile = !tsTileOnTile">
                                    <label for="tg.ts-tile-on-tile">TILE ON TILE</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.ts-cement-board" id="tg.ts-cement-board" [checked]="tsCementBoard" (change)="tsCementBoard = !tsCementBoard">
                                    <label for="tg.ts-cement-board">CEMENT BOARD</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tg.ts-plaster" id="tg.ts-plaster" [checked]="tsPlaster" (change)="tsPlaster = !tsPlaster">
                                    <label for="tg.ts-plaster">PLASTER / CONCRETE</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xl-6 form-group m-form__group">
                            <label><strong>Epoxy Type</strong></label>
                            <div class="form-group m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tje.type-jfe" id="tje.type-jfe" [checked]="jointFillerEpoxy" (change)="jointFillerEpoxy = !jointFillerEpoxy">
                                    <label for="tje.type-jfe">Join Filler Epoxy</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tje.type-gbe" id="tje.type-gbe" [checked]="glassBondEpoxy" (change)="glassBondEpoxy = !glassBondEpoxy">
                                    <label for="tje.type-gbe">Glass Bond Epoxy</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tje.type-ce-2pe" id="tje.type-ce-2pe" [checked]="twoPartEpoxy" (change)="twoPartEpoxy = !twoPartEpoxy">
                                    <label for="tje.type-ce-2pe">Two Part Epoxy</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="tje.type-ce-3pe" id="tje.type-ce-3pe" [checked]="threePartEpoxy" (change)="threePartEpoxy = !threePartEpoxy">
                                    <label for="tje.type-ce-3pe">Three Part Epoxy</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xl-6 form-group m-form__group">
                            <label><strong>Water Proof Type</strong></label>
                            <div class="form-group m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="wp.type-2wp" id="wp.type-2wp" [checked]="twoKWaterProof" (change)="twoKWaterProof = !twoKWaterProof">
                                    <label for="wp.type-2wp">2K WaterProof</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="wp.type-bwp" id="wp.type-bwp" [checked]="bathroomWaterProof" (change)="bathroomWaterProof = !bathroomWaterProof">
                                    <label for="wp.type-bwp">Bathroom Water Proof</label>
                                </div>
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" name="wp.type-awp" id="wp.type-awp" [checked]="acrylicWaterProof" (change)="acrylicWaterProof = !acrylicWaterProof">
                                    <label for="wp.type-awp">Acrylic Water Proof</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <label >Description</label>
                            <span class="text-danger">*</span>
                            <ckeditor formControlName="description" [editor]="ClassicEditor"></ckeditor>
                            <div *ngIf="form.controls.description.invalid && (form.controls.description.dirty || form.controls.description.touched)" class="text text-danger">
                                <div *ngIf="form.controls.description.errors.required">
                                    Description is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" style="text-align:right;">
                        <button [disabled]="loading" type="submit" class="btn btn-primary me-1">Save Product Details</button>
                        <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Closed')">Cancel</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xl-3 form-group m-form__group">
                <div class="card shadow shadow-showcase">
                    <div class="card-header" style="padding-bottom:10px;border-bottom:none;"><h5>Images / Tech Sheet</h5></div>
                    <div class="card-body row">
                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <input #attachments id="productImage" type="file" (change)="addImage($event)" style="display: none;" accept="image/*"/>
                            <button type="button" class="btn btn-outline-primary m-t-10 m-b-10" onclick="document.getElementById('productImage').click();">
                                <i class="p-r-10 fa fa-plus"></i>
                                <span *ngIf="imageSrc"> Update Product Image </span>
                                <span *ngIf="!imageSrc"> Add Product Image </span>
                            </button>
                            <div *ngIf="imageSrc" class="file-asset">
                                <img style="max-width:200px" [src]="imageSrc" alt="Product Image" />
                                <span><strong>{{selectedImage?.name}}</strong></span>
                                <div class="remove-file" (click)="removeImage()"><a href="javascript:void(0)"><i style="font-size: 22px;color:#ff5370 !important" class="fa fa-trash-o"></i></a></div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <input #attachments id="productTechSheet" type="file" (change)="addTechSheet($event)" style="display: none;" accept="application/pdf"/>
                            <button type="button" class="btn btn-outline-primary m-t-10 m-b-10" onclick="document.getElementById('productTechSheet').click();">
                                <i class="p-r-10 fa fa-plus"></i>
                                <span *ngIf="techSheetAvaialble"> Update Technical Sheet </span>
                                <span *ngIf="!techSheetAvaialble"> Add Technical Sheet </span>
                            </button>
                            <div *ngIf="techSheetAvaialble && selectedTechSheet" style="max-width:130px !important"  class="file-asset">
                                <img style="max-width:100px" src="/assets/images/PDF_file_icon.png" alt="Product Image" />
                                <span><strong>{{selectedTechSheet.name}}</strong></span>
                                <div class="remove-file" (click)="removeTechSheet()"><a href="javascript:void(0)"><i style="font-size: 22px;color:#ff5370 !important" class="fa fa-trash-o"></i></a></div>
                            </div>
                            <div *ngIf="techSheetAvaialble && !selectedTechSheet" style="max-width:130px !important"  class="file-asset">
                                <a href="{{techSheetUrl}}" target="_blank"><img style="max-width:100px" src="/assets/images/PDF_file_icon.png" alt="Product Image" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>